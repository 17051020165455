nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.75rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
  }
  
  nav a {
    position: relative; /* Added */
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 2em;
  }
  
  nav a:hover {
    background: rgba(0, 0, 0, 0.3);
  }
  
  nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
  }
  
  nav a .icon-name {
    display: none;
    background: #000;
    color: #fff;
    padding: 4px 8px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px; /* Adjust the font size as needed */
  }
  
  nav a:hover .icon-name {
    display: inline-block;
  }
  nav a:hover svg {
    transform: scale(1.5); /* Increase the scale factor as needed */
  }