




#professional,
#skills {
  background-color:var(--color-bg-variant);
  border-radius: 2rem;
  padding: 2.4rem;
  transition: var(--transition);
  border:1px solid transparent;
  margin-bottom: 1rem;

  
}
#professional{
    margin-left: 4rem;
    margin-right: 3rem;
   
}
.align-items-start {
    align-items: flex-start;
  }
  #professional:hover,
  #skills:hover {
    background-color: transparent;
    border-color:var(--color-primary-variant);
    cursor:default;
  }







.progress-title {
    font-size: 16px;
    font-weight: 700;
    margin: 15px 0 20px;
    font-family: 'Raleway';
}

.progress {
    height: 0.1rem;
    background-color:transparent;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 30px;
    overflow: visible;
}

.progress .progress-bar {
    position: relative;
    background-color:var(--color-light);
    height: 0.15rem;
    animation: animate-positive 2s;
    overflow: visible;
   

    
}

.progress .progress-value {
    position: absolute;
    top: -30px;
    right: 8px;
    font-size: 17px;
    font-weight: bold;
    font-style: italic;
    color:var(--color-light);
}


@-webkit-keyframes animate-positive {
    0% {
        width: 0%;
    }
}

@keyframes animate-positive {
    0% {
        width: 0%;
    }
}

.section-title {
    font-size: 45px;
}

.service__title {
    padding: 8px 0;
    border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
    padding-bottom: 4px;
}


/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}	
