header{
    height:100vh;
    padding-top:2rem;
    overflow: hidden;
}

.header__container{
    text-align:center;
    height:100%;
    position: relative;
    
}
h1{
    font-size: 0.75rem;
}

/*=============CTA==============*/

.cta{
    margin-top: 1rem;
    display: flex;
    gap: 1.2em;
    justify-content: center;
    padding-top: -2px;
}

/*=============Socials==============*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left:0;
    bottom: 10rem;
}

.header__socials::after{
    content: "";
    width: 0.01rem;
    height: 2rem;
    background:var(--color-primary);
}

/*=============Me i.e image ==============*/
.me{
    background: linear-gradient( var(--color-primary), transparent);
    width:24rem;
    height:33rem;
    position: absolute;
    left:50%;
    transform: translateX(-50%);
    margin-top: 6.5rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    
}
/*=============Scroll ==============*/
.scroll__down{
    position: absolute;
    right:-2.3rem;
    bottom: 15rem;
    transform: rotate(90deg);
    font-weight: 300;
    font: size 0.9rem;

}

/* Medium devices */
@media screen and (max-width:1024px) {
    .header{
        height: 68vh;
    }
}
/* small devices */
@media screen and (max-width:600px) {
    .header{
        height: 88vh;
    }
    .header__socials,
    .scroll__down{
        display: none;
    }
    .me{
        width:23rem;
         height:33rem;
       
        
    }
}

@media  (min-width:1359px) and (max-width:2000px)  {
    .header{
        height: 100vh;
    }
  /*  .me{
   
        width: 24rem;
        height: 33rem;
        margin-top: 7rem;
        
    }*/
    .cta{
        margin-top: 2rem;
    }
    .scroll__down,
    .header__socials
    {
        font-size: 1.5rem;
    
    }
}




